import { useEffect } from "react";
import { getAccessToken, getRefreshToken } from "../utils/common";
import { useNavigate } from "react-router-dom";

export default () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (getAccessToken() && getRefreshToken()) {
            navigate("/adm");
        } else {
            navigate("/login");
        }
    }, []);

    return "Main";
};
