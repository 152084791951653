import React from "react";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { setToken } from "../utils/common";

const Login = () => {
    const navigate = useNavigate();
    const inputId = useRef();
    const inputPw = useRef();
    const inputRemember = useRef();

    useEffect(() => {
        inputId.current.focus();
        
        const isSave = localStorage.getItem("is_save");
        if (isSave == 1) {
            inputId.current.value = localStorage.getItem("logined_id");
            inputRemember.current.checked = true;
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        //유용한놈!
        const frm = Object.fromEntries(new FormData(e.target).entries());

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/auth/login`,
            method: "POST",
            data: frm,
        });

        console.log(data);

        if (data.code === 0) {
            alert(data.msg);
            return;
        }

        if (data.access_token !== "") {
            if (frm.remember) {
                localStorage.setItem("is_save", 1);
            }
            localStorage.setItem("logined_id", data.id);
            localStorage.setItem("logined_name", data.name1);
            setToken(data.access_token, data.refresh_token);
            window.location.href = "/adm";
        }
    };

    return (
        <div className="d-flex justify-content-center align-items-center bg-light vh-100">
            <form onSubmit={handleSubmit}>
                <div className="d-flex flex-row">
                    <div className="d-flex flex-column">
                        <input
                            ref={inputId}
                            type="text"
                            className="form-control fs-12"
                            placeholder="ID"
                            name="id"
                            autoComplete="username"
                            required
                        />
                        <input
                            ref={inputPw}
                            type="password"
                            className="form-control mt-1 fs-12"
                            placeholder="PW"
                            name="pw"
                            autoComplete="current-password"
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary ms-1">
                        Login
                    </button>
                </div>

                <div className="ms-1 mt-2 mb-3 d-flex flex-row align-items-center">
                    <input
                        ref={inputRemember}
                        type="checkbox"
                        className="form-check-input mt-0 me-2"
                        id="remember"
                        name="remember"
                    />
                    <label className="form-check-label fs-12" htmlFor="remember">
                        아이디 기억
                    </label>
                </div>
            </form>
        </div>
    );
};

export default Login;
