import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { getAccessToken } from "../../utils/common";
import Layout from "./Layout";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const chart = {
    type: "spline",
};
const yAxis = {
    labels: {
        formatter: function () {
            return this.value;
        },
    },
    allowDecimals: false,
    title: {
        text: "명",
    },
};

export default observer(() => {
    const [options, setOptions] = useState({});
    const { gbn } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch();
    }, [gbn]);

    const fetch = async () => {
        appStore.setLoading(true);
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/admin/analyzer/${gbn}`,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });

        if (gbn == "graph1") {
            setOptions({
                chart,
                yAxis,
                xAxis: {
                    categories: data.date,
                },
                title: {
                    text: "전체방문자",
                },
                series: [
                    { name: "총방문자", data: data.ttl },
                    { name: "재방문자", data: data.re },
                    { name: "신규방문자", data: data.new1 },
                ],
            });
        } else if (gbn == "graph2") {
            setOptions({
                chart,
                yAxis,
                xAxis: {
                    categories: data.date,
                },
                title: {
                    text: "트래픽수",
                },
                series: [{ name: "트래픽", data: data.traffic }],
            });
        } else if (gbn == "graph3") {
            setOptions({
                chart,
                yAxis,
                xAxis: {
                    categories: data.time,
                },
                title: {
                    text: "시간대별 트래픽수",
                },
                series: [
                    { name: "오늘", data: data.today },
                    { name: "어제", data: data.yesterday },
                    { name: "1주전", data: data.weekago },
                ],
            });
        }

        appStore.setLoading(false);
    };

    return (
        <Layout>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Layout>
    );
});
