import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getAccessToken } from "../utils/common";
import { toast } from "react-toastify";

class MemoStore {
    memo = "";

    constructor() {
        makeAutoObservable(this);
    }

    setMemo(newMemo) {
        this.memo = newMemo;
    }

    async loadMemo(pathname) {
        const id = localStorage.getItem("logined_id");

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/admin/load_memo?id=${id}&page_url=${pathname}`,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        if (!data.memo || data.memo === "" || data.memo === null) {
            this.setMemo("");
        } else {
            this.setMemo(data.memo ? data.memo : "");
        }
    }

    async saveMemo(pathname) {
        const id = localStorage.getItem("logined_id");
        const obj = new URLSearchParams({
            table: "MEMO_tbl",
            id,
            memo: this.memo,
            page_url: pathname,
        });
        const url = `${process.env.REACT_APP_HOST}/admin/save_memo`;
        console.log(url);
        const { data } = await axios({
            url,
            method: "POST",
            data: obj,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        if (data.code === 1) {
            toast("저장되었습니다.", {
                position: "top-center",
            });
        }
    }
}

export const memoStore = new MemoStore();
