import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { myprofileStore } from "../../stores/MyprofileStore";

import React from "react";
import { useEffect, useState } from "react";
import Layout from "./Layout";

export default observer(() => {

    useEffect(() => {
        (async () => {
            appStore.isLoading = true;
            await myprofileStore.fetch();
            appStore.isLoading = false;
        })();
    }, []);

    const handleSubmit = async (e) => {
        appStore.isLoading = true;
        e.preventDefault();
        //유용한놈!
        const frm = Object.fromEntries(new FormData(e.target).entries());
        if (frm.pass1 == "") {
            delete frm.pass1;
        }
        await myprofileStore.update(frm);
        appStore.isLoading = false;
    };

    return (
        <Layout>
            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>아이디</td>
                            <td>
                                <input
                                    type="text"
                                    name="id"
                                    className="form-control form-control-sm"
                                    placeholder="변경시에만 입력하세요."
                                    defaultValue={myprofileStore.data.id}
                                    readOnly
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>패스워드</td>
                            <td>
                                <input
                                    type="password"
                                    name="pass1"
                                    className="form-control form-control-sm"
                                    placeholder="변경시에만 입력하세요."
                                    defaultValue=""
                                />
                            </td>
                        </tr>

                        <tr>
                            <td>이름</td>
                            <td>
                                <input
                                    type="text"
                                    name="name1"
                                    className="form-control form-control-sm"
                                    defaultValue={myprofileStore.data.name1}
                                    required
                                />
                            </td>
                        </tr>

                        <tr>
                            <td colSpan={2} align="right">
                                <button type="submit" className="btn btn-primary btn-sm mt-1">
                                    저장
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </Layout>
    );
});
