import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getAccessToken } from "../utils/common";
import { toast } from "react-toastify";
class CrudStore {
    constructor() {
        makeAutoObservable(this);
    }

    async readOne(params) {
        const url = `${process.env.REACT_APP_HOST}/crud/read?idx=${params.idx}&table=${params.table}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        return data;
    }

    async write(obj) {
        console.log("writer", obj);
        if (!obj || !obj.table) {
            return {};
        }
        const url = `${process.env.REACT_APP_HOST}/crud/write`;
        console.log(url);
        const { data } = await axios({
            url,
            method: "POST",
            data: obj,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        if (obj.idx) {
            toast("수정되었습니다.", {
                position: "top-center",
            });
            return obj;
        } else {
            toast("등록되었습니다.", {
                position: "top-center",
            });
            const result = {
                idx: parseInt(data.insertId),
                ...obj,
            };
            return result;
        }
    }

    async updateIsUse(arr, table) {
        console.log(arr);

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/crud/update_is_use`,
            method: "POST",
            data: {
                idx: arr,
                table,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        toast(data.msg, {
            position: "top-center",
        });
    }

    async delete(arr, table) {
        console.log(arr);

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/crud/delete`,
            method: "POST",
            data: {
                idx: arr,
                table,
            },
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        toast(data.msg, {
            position: "top-center",
        });
    }
}

export const crudStore = new CrudStore();
