import axios from "axios";
import { useState } from "react";

const MediumParse = () => {
    const [url, setUrl] = useState("");
    const [html, seHtml] = useState("<div class='bg-danger'>html</div>");

    const handleParse = async () => {
        if (url == "") {
            return;
        }

        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/medium_parse`,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: {
                url,
            },
        });
        seHtml(data);
    };

    return (
        <div>
            <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
            <button type="button" className="btn btn-sm btn-light" onClick={handleParse}>
                적용
            </button>

            <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
    );
};

export default MediumParse;
