const CryptoJS = require("crypto-js");



export const getAccessToken = () => {
    const token = localStorage.getItem("access_token");
    if (token) {
        return token;
    }
    return null;
};

export const getRefreshToken = () => {
    const token = localStorage.getItem("refresh_token");
    if (token) {
        return token;
    }
    return null;
};

export const removeToken = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
};

export const setToken = (accessToken, refreshToken) => {
    localStorage.setItem("access_token", accessToken);
    localStorage.setItem("refresh_token", refreshToken);
};

export const utilConvertToMillis = (strTime) => {
    const time = new Date(strTime).getTime() / 1000;
    const currentTime = Math.floor(new Date().getTime() / 1000);
    const inputTime = time;
    const diffTime = currentTime - inputTime;
    var postTime;
    switch (true) {
        case diffTime < 60:
            postTime = "방금";
            break;
        case diffTime < 3600:
            postTime = parseInt(diffTime / 60) + "분 전";
            break;
        case diffTime < 86400:
            postTime = parseInt(diffTime / 3600) + "시간 전";
            break;
        case diffTime < 604800:
            postTime = parseInt(diffTime / 86400) + "일 전";
            break;
        case diffTime > 604800:
            var date = new Date(time * 1000);
            var month = date.getMonth() + 1;
            var day = date.getDate();
            if (date.getMonth() + 1 < 10) {
                month = "0" + date.getMonth() + 1;
            }
            if (date.getDate() < 10) {
                day = "0" + date.getDate();
            }
            postTime = date.getFullYear() + "-" + month + "-" + day;
            break;
        default:
            postTime = time;
    }
    return postTime;
};

export const replaceAll = (str, searchStr, replaceStr) => {
    if (str === "") {
        return str;
    }
    return str.split(searchStr).join(replaceStr);
};

export const getToday = () => {
    const now = new Date();
    const todayYear = now.getFullYear();
    const todayMonth = now.getMonth() + 1;
    const todayDate = now.getDate();
    return `${todayYear}-${todayMonth}-${todayDate}`;
};

export const getTodayTime = () => {
    const now = new Date(); // 현재 날짜 및 시간
    const todayYear = now.getFullYear();
    const todayMonth = now.getMonth() + 1;
    const todayDate = now.getDate();
    const week = ["일", "월", "화", "수", "목", "금", "토"];
    const dayOfWeek = week[now.getDay()];
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${todayYear}년 ${todayMonth}월 ${todayDate}일 ${hours}시 ${minutes}분 ${dayOfWeek}요일`;
};

const encrypt = (text) => {
    try {
        const secretkey = "kimluabehappygoo";
        const key = CryptoJS.enc.Utf8.parse(secretkey);
        const iv = CryptoJS.enc.Utf8.parse(secretkey.substring(0, 16));
        const cipherText = CryptoJS.AES.encrypt(`${text}`, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();
        return cipherText;
    } catch (err) {
        console.log(err);
        return "";
    }
};

const decrypt = (text) => {
    try {
        const secretkey = "kimluabehappygoo";
        const key = CryptoJS.enc.Utf8.parse(secretkey);
        const iv = CryptoJS.enc.Utf8.parse(secretkey.substring(0, 16));
        const decrypted = CryptoJS.AES.decrypt(`${text}`, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        });
        text = decrypted.toString(CryptoJS.enc.Utf8);
        return text;
    } catch (err) {
        console.log(err);
        return "";
    }
};
