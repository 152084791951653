import axios from "axios";
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { Link } from "react-router-dom";
import Layout from "./Layout";

export default observer(() => {
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        console.log(appStore.menuList);
        setMenus(appStore.menuList);
    }, []);

    return (
        <Layout>
            <table className="table table-borderless table-sm">
                <tbody>
                    {menus.map((row, i) => (
                        <tr key={i}>
                            <th valign="middle">{row.title}</th>
                            <td>
                                {row.child.map((row2, j) => (
                                    <Link key={j} to={`${row2.link}`} className="btn btn-light me-2 mb-1">
                                        {row2.title}
                                    </Link>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Layout>
    );
});
