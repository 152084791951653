import axios from "axios";
import React, { useEffect, useState } from "react";

import Layout from "./Layout";
import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { getAccessToken } from "../../utils/common";

export default observer(() => {
    const [data, setData] = useState([]);
    const [currentTime, setCurrentTime] = useState("");

    useEffect(() => {
        fetch();
        // 4초마다 fetch 함수를 호출하는 interval 설정
        const interval = setInterval(() => {
            fetch();
        }, 4000);

        // 컴포넌트 언마운트 시 interval 정리
        return () => clearInterval(interval);
    }, []);

    const fetch = async () => {
        const { data } = await axios({
            url: `${process.env.REACT_APP_HOST}/admin/liveuser`,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);
        setData(data.list);
        setCurrentTime(data.currentTime);
    };

    return (
        <Layout>
            <div>
                {currentTime}
                <b className="text-danger ms-2">{data.length}</b> 명 접속 (4분동안 액션이 없으면 카운팅에서 제외
                됩니다.)
            </div>
            <hr />

            {data.map((row, i) => (
                <div key={i}>
                    {i + 1}. {row.url} | {row.id} | {row.date}
                </div>
            ))}
        </Layout>
    );
});
