import React from "react";

import { useEffect, useState } from "react";
import { getAccessToken, getRefreshToken, removeToken, setToken } from "../../utils/common";
import axios from "axios";

const TokenVerify = () => {
    const [isExpired, setExpired] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await axios({
                url: `${process.env.REACT_APP_HOST}/auth/access_token_verify`,
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            });
            // console.log(data);
            if (data.code === 0) {
                // 토큰이 만료 되었으면 RefreshToken 으로 다시 날린다!
                const { data } = await axios({
                    url: `${process.env.REACT_APP_HOST}/auth/refresh_token_verify`,
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        Authorization: `Bearer ${getAccessToken()}`,
                        refresh_token: getRefreshToken(),
                    },
                });
                // console.log(data);

                if (data.code === 0) {
                    setExpired(true);
                } else {
                    setToken(data.access_token, data.refresh_token);
                    setExpired(false);
                }
            } else {
                setExpired(false);
            }
        })();
    }, []);

    return (
        <div>
            {isExpired && (
                <div className="modal bg-dark bg-opacity-50" style={{ display: "block" }} id="backdrop">
                    <div className="modal-dialog modal modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-body text-center p-4">
                                <h3>토큰이 만료 되었습니다.</h3>
                                <h5>다시 로그인 해주세요.</h5>
                                <button
                                    type="button"
                                    className="btn w-100 btn-primary mt-4"
                                    onClick={() => {
                                        removeToken();
                                        window.location.href = "/login";
                                    }}
                                >
                                    Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TokenVerify;
