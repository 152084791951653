import React, { useEffect, useState } from "react";
import axios from "axios";
import { getAccessToken } from "../../utils/common";
import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { crudStore } from "../../stores/CrudStore";
import ReplyPager from "./ReplyPager";
import ReplyBody from "./ReplyBody";
import ReplyDetailList from "./ReplyDetailList";

export default observer(() => {
    const [data, setData] = useState([]);
    const [pageHelper, setPageHelper] = useState({});

    useEffect(() => {
        fetch();
    }, [appStore.params.refresh2]);

    const fetch = async () => {
        const url = `${process.env.REACT_APP_HOST}/article/reply_list/${appStore.params.idx}/${appStore.params.id}?page=${appStore.params.page2}&sort1=time`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        setData(data.list);
        setPageHelper(data.page_helper);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const frm = Object.fromEntries(new FormData(e.target).entries());
        const data = await crudStore.write(frm);
        console.log(data);
        e.target.memo.value = "";
        appStore.setParams({
            page2: 1,
            refresh2: Date.now(),
        });
    };

    return (
        <>
            <div className="d-flex justify-content-between pe-3">
                <h3 className="ms-3">댓글</h3>
                <ReplyPager pageHelper={pageHelper} />
            </div>
            {data.map((row, i) => (
                <div key={i} style={{ fontSize: "14px" }}>
                    <ReplyBody row={row} />
                </div>
            ))}

            <form onSubmit={handleSubmit}>
                <input type="hidden" name="table" value="BOARD_tbl" />
                <input type="hidden" name="step" value="2" />
                <input type="hidden" name="parent_idx" value={appStore.params.idx} />
                <input type="hidden" name="id" value={appStore.params.id} />
                <input type="hidden" name="name1" value={appStore.params.name1} />

                <div className="input-group input-group-sm mb-3 p-3">
                    <textarea
                        name="memo"
                        className="form-control"
                        rows="3"
                        placeholder="내용을 입력해주세요."
                        required
                    ></textarea>
                    <button className="btn btn-outline-secondary" type="submit">
                        댓글남기기
                    </button>
                </div>
            </form>
            <ReplyPager pageHelper={pageHelper} />
            <br />
            {appStore.params.idx2 > -1 && <ReplyDetailList />}
        </>
    );
});
