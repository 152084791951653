import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "./Layout";
import { getAccessToken } from "../../utils/common";
import { observer } from "mobx-react";
import { crudStore } from "../../stores/CrudStore";
import { appStore } from "../../stores/AppStore";
import Table from "../components/Table";
import MyModal from "../components/MyModal";
import Field from "../components/Field";
import SearchForm from "../components/SearchForm";
import Pager from "../components/Pager";
import ImageDrop from "../components/ImageDrop";

const columns = [
    { textName: "번호", colName: "idx", isSort: true },
    { textName: "사진", colName: "filename0", isSort: false },
    { textName: "이름", colName: "name1", isSort: true },
    { textName: "아이디", colName: "id", isSort: true },
    { textName: "등록일", colName: "created", isSort: true },
    { textName: "수정일", colName: "modified", isSort: true },
];

export default observer(() => {
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const [pageHelper, setPageHelper] = useState({});

    useEffect(() => {
        appStore.setParams({
            table: "MEMB_tbl",
            page: 1,
        });
    }, []);

    useEffect(() => {
        fetch();
    }, [appStore.params.refresh]);

    const fetch = async () => {
        appStore.setLoading(true);
        const url = `${process.env.REACT_APP_HOST}/admin/manager?page=${appStore.params.page}&search_column=${appStore.params.search_column}&search_value=${appStore.params.search_value}&orderby=${appStore.params.orderby}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        setData(data.list);
        setPageHelper(data.page_helper);

        appStore.setLoading(false);
    };

    const handleAdd = () => {
        setRow(null);
        appStore.setParams({ idx: 0 });
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        // 선택된 항목을 배열로 수집
        const arr =
            e.target.idx.length === undefined
                ? [e.target.idx.value] // 단일 항목 처리
                : [...e.target.idx].filter((obj) => obj.checked).map((obj) => obj.value); // 여러 항목 처리

        // 선택된 항목이 없을 경우
        if (arr.length === 0) {
            return window.alert("체크된 항목이 없습니다.");
        }

        // 삭제 확인
        if (!window.confirm("체크된 항목을 삭제 하시겠습니까?")) return;

        // 삭제 수행
        await crudStore.delete(arr, appStore.params.table);

        // 상태 업데이트
        appStore.setParams({ refresh: Date.now() });
    };

    return (
        <Layout>
            <SearchForm
                columns={[
                    { value: "name1", text: "이름" },
                    { value: "id", text: "아이디" },
                ]}
            />
            <Table columns={columns} onAdd={handleAdd} onDelete={handleDelete}>
                {data.map((row, i) => (
                    <tr key={row.idx}>
                        <td className="text-center align-middle">
                            <input type="checkbox" name="idx" value={row.idx} />
                        </td>
                        <td className="text-center text-nowrap align-middle">{row.idx}</td>
                        <td className="text-center align-middle">
                            {row.filename0 && (
                                <img src={row.filename0} width={30} height={30} style={{ objectFit: "cover" }} />
                            )}
                        </td>
                        <td className="text-nowrap align-middle">
                            <button
                                type="button"
                                className="btn btn-sm btn-link fs-12"
                                onClick={() => {
                                    setRow(row);
                                    appStore.setParams({
                                        idx: row.idx,
                                    });
                                }}
                            >
                                {row.name1}
                            </button>
                        </td>
                        <td className="text-center text-nowrap align-middle">{row.id}</td>
                        <td className="text-center text-nowrap align-middle">{row.created}</td>
                        <td className="text-center text-nowrap align-middle">{row.modified}</td>
                    </tr>
                ))}
            </Table>

            <Pager pageHelper={pageHelper} />

            {appStore.params.idx > -1 && <ManagerSet data={row} />}
        </Layout>
    );
});

const ManagerSet = observer(({ data }) => {
    const handleClose = () => {
        appStore.setParams({
            idx: -1,
            refresh: Date.now(),
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //유용한놈!
        const frm = Object.fromEntries(new FormData(e.target).entries());
        if (frm.pass1 == "") {
            delete frm.pass1;
        }

        if (frm.idx == 0) {
            delete frm["idx"];
        }

        await crudStore.write(frm);

        appStore.setParams({
            idx: -1,
            refresh: Date.now(),
        });
    };

    return (
        <MyModal onClose={handleClose}>
            <form onSubmit={handleSubmit} className="p-3">
                <input name="idx" type="hidden" defaultValue={appStore.params.idx} />
                <input name="table" type="hidden" defaultValue={appStore.params.table} />
                <input name="level1" type="hidden" defaultValue={2} />

                <Field
                    title="아이디"
                    name1="id"
                    type="text"
                    readOnly={data && true}
                    required={true}
                    defaultValue={data ? data.id : ""}
                />
                <br />
                <Field
                    title="패스워드"
                    name1="pass1"
                    type="password"
                    readOnly={false}
                    required={data ? false : true}
                    defaultValue={""}
                    placeholder="변경시에만 입력하세요."
                />
                <br />
                <Field
                    title="이름"
                    name1="name1"
                    type="text"
                    readOnly={false}
                    required={true}
                    defaultValue={data ? data.name1 : ""}
                />

                <br />
                <div className="me-1 mt-2" style={{ width: "80px", height: "80px" }}>
                    <ImageDrop filename={data ? data.filename0 : ""} fieldname={"filename0"} />
                </div>

                <div className="d-flex justify-content-between">
                    <div></div>
                    <button type="submit" className="btn btn-primary btn-sm">
                        등록
                    </button>
                </div>
            </form>
        </MyModal>
    );
});
