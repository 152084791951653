import { makeAutoObservable } from "mobx";
import menus from "../utils/menus";

class AppStore {
    isLoading = false;
    isMenuShow = true;
    menuList = [];
    params = {
        table: "",
        idx: -1,
        board_id: "",
        search_column: "",
        search_value: "",
        orderby: "idx DESC",
        page: 1,
        refresh: 0,
        // 댓글용
        page2: 1,
        refresh2: 0,
        idx2: -1,
    };

    constructor() {
        makeAutoObservable(this);
        this.initializeMenuList();
    }

    setMenuShow(flag) {
        this.isMenuShow = flag;
    }

    initializeMenuList() {
        for (const obj of menus) {
            obj.isOpen = false;
            for (const obj2 of obj.child) {
                obj2.isSelected = false;
            }
            this.menuList.push(obj);
        }
    }

    toggleLeftMenu() {
        this.isMenuShow = !this.isMenuShow;
    }

    setMenuExpand(i) {
        if (i >= 0 && i < this.menuList.length) {
            this.menuList[i].isOpen = !this.menuList[i].isOpen;
        }
    }

    setSelected(i, j) {
        if (i >= 0 && i < this.menuList.length && j >= 0 && j < this.menuList[i].child.length) {
            // 모든 메뉴와 하위 메뉴를 초기화하는 대신, 현재 선택된 항목만 변경
            this.menuList.forEach((menu, menuIndex) => {
                menu.child.forEach((child, childIndex) => {
                    child.isSelected = menuIndex === i && childIndex === j;
                });
            });
        } else {
            console.error("Invalid indices provided to setSelected");
        }
    }

    setLoading(flag) {
        this.isLoading = flag;
    }

    setParams(newParams) {
        this.params = {
            ...this.params,
            ...newParams,
        };
    }
}

export const appStore = new AppStore();
