import React, { useEffect, useState } from "react";
import axios from "axios";
import Layout from "./Layout";
import { getAccessToken } from "../../utils/common";
import { observer } from "mobx-react";
import { crudStore } from "../../stores/CrudStore";
import { appStore } from "../../stores/AppStore";
import Table from "../components/Table";
import MyModal from "../components/MyModal";
import Field from "../components/Field";

const columns = [
    { textName: "번호", colName: "idx", isSort: false },
    { textName: "권한레벨", colName: "level1", isSort: false },
    { textName: "권한명", colName: "name1", isSort: false },
    { textName: "등록일", colName: "created", isSort: false },
    { textName: "수정일", colName: "modified", isSort: false },
];

const Grade = observer(() => {
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});

    useEffect(() => {
        appStore.setParams({
            table: "GRADE_tbl",
            page: 1,
        });
    }, []);

    useEffect(() => {
        fetch();
    }, [appStore.params.refresh]);

    const fetch = async () => {
        appStore.setLoading(true);
        const url = `${process.env.REACT_APP_HOST}/admin/grade`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        setData(data);
        appStore.setLoading(false);
    };

    const handleAdd = () => {
        setRow({
            idx: "",
            level1: "",
            name1: "",
            show_menu_link: "",
        });
        appStore.setParams({ idx: 0 });
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        // 선택된 항목을 배열로 수집
        const arr =
            e.target.idx.length === undefined
                ? [e.target.idx.value] // 단일 항목 처리
                : [...e.target.idx].filter((obj) => obj.checked).map((obj) => obj.value); // 여러 항목 처리

        // 선택된 항목이 없을 경우
        if (arr.length === 0) {
            return window.alert("체크된 항목이 없습니다.");
        }

        // 삭제 확인
        if (!window.confirm("체크된 항목을 삭제 하시겠습니까?")) return;

        // 삭제 수행
        await crudStore.delete(arr, appStore.params.table);

        // 상태 업데이트
        appStore.setParams({ refresh: Date.now() });
    };

    return (
        <Layout>
            <Table columns={columns} onAdd={handleAdd} onDelete={handleDelete}>
                {data.map((row, i) => (
                    <tr key={i}>
                        <td className="text-center align-middle p-0">
                            <input type="checkbox" name="idx" value={row.idx} />
                        </td>
                        <td className="text-center align-middle text-nowrap">{row.idx}</td>
                        <td className="text-center align-middle text-nowrap">{row.level1}</td>
                        <td className="text-nowrap align-middle">
                            <button
                                type="button"
                                className="btn btn-sm btn-link"
                                onClick={() => {
                                    setRow(row);
                                    appStore.setParams({ idx: row.idx });
                                }}
                            >
                                {row.name1}
                            </button>
                        </td>
                        <td className="text-center align-middle text-nowrap">{row.created}</td>
                        <td className="text-center align-middle text-nowrap">{row.modified}</td>
                    </tr>
                ))}
            </Table>

            {appStore.params.idx > -1 && <GradeSet data={row} />}
        </Layout>
    );
});

const GradeSet = observer(({ data }) => {
    const handleClose = () => {
        appStore.setParams({
            idx: -1,
            refresh: Date.now(),
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //유용한놈!
        const frm = Object.fromEntries(new FormData(e.target).entries());
        delete frm["link"];
        var show_menu_link = "";
        if (e.target.link.length == undefined) {
            show_menu_link += "," + e.target.link.value;
        } else {
            for (const obj of e.target.link) {
                if (obj.checked) {
                    show_menu_link += "," + obj.value;
                }
            }
        }

        frm.show_menu_link = show_menu_link.substr(1);

        if (frm.idx == 0) {
            delete frm["idx"];
        }

        await crudStore.write(frm);

        appStore.setParams({
            idx: -1,
            refresh: Date.now(),
        });
    };

    return (
        <MyModal onClose={handleClose}>
            <form onSubmit={handleSubmit} className="p-3">
                <input name="idx" type="hidden" defaultValue={appStore.params.idx} />
                <input name="table" type="hidden" defaultValue={appStore.params.table} />

                <Field
                    title="권한레벨"
                    name1="level1"
                    type="number"
                    readOnly={false}
                    required={true}
                    defaultValue={data.level1}
                />
                <br />
                <Field
                    title="권한명"
                    name1="name1"
                    type="text"
                    readOnly={false}
                    required={true}
                    defaultValue={data.name1}
                />
                <br />

                <table className="table table-borderless table-sm">
                    <tbody>
                        {appStore.menuList.map((row, i) => (
                            <tr key={i}>
                                <th valign="middle">{row.title}</th>
                                <td className="d-flex flex-row">
                                    {row.child.map((row2, j) => (
                                        <div key={j} className="form-check mb-0 ms-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                name="link"
                                                defaultChecked={
                                                    data.show_menu_link
                                                        ? data.show_menu_link.includes(row2.link)
                                                        : false
                                                }
                                                value={row2.link}
                                                id={`id${i}_${j}`}
                                            />
                                            <label className="form-check-label" htmlFor={`id${i}_${j}`}>
                                                {row2.title}
                                            </label>
                                        </div>
                                    ))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="d-flex justify-content-between">
                    <div></div>
                    <button type="submit" className="btn btn-primary btn-sm">
                        등록
                    </button>
                </div>
            </form>
        </MyModal>
    );
});

export default Grade;
