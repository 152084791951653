import { appStore } from "../../stores/AppStore";
import { crudStore } from "../../stores/CrudStore";
import { observer } from "mobx-react";

export default observer(({ children, columns, onAdd, onDelete }) => {
    const chkList = document.querySelectorAll("input[name='idx']");
    for (const o of chkList) {
        o.checked = false;
    }

    const checkAll = (e) => {
        const chkList = document.querySelectorAll("input[name='idx']");
        for (const o of chkList) {
            o.checked = e.target.checked;
        }
    };

    // const handleDelete = async (e) => {
    //     e.preventDefault();
    //     const arr = [];
    //     if (e.target.idx.length === undefined) {
    //         arr.push(e.target.idx.value);
    //     } else {
    //         for (const obj of e.target.idx) {
    //             if (obj.checked) {
    //                 arr.push(obj.value);
    //             }
    //         }
    //     }

    //     if (arr.length === 0) {
    //         window.alert("체크된 항목이 없습니다.");
    //         return;
    //     } else {
    //         if (window.confirm("체크된 항목을 삭제 하시겠습니까?")) {
    //             await crudStore.delete(arr, table);
    //         } else {
    //             return;
    //         }
    //     }

    //     appStore.setParams({
    //         refresh: Date.now(),
    //     });
    // };

    return (
        <div className="table-responsive">
            <form onSubmit={onDelete}>
                <table className="table table-sm">
                    <tbody>
                        <tr>
                            <th className="text-center p-0 align-middle" style={{ width: "60px" }}>
                                <input type="checkbox" onClick={checkAll} />
                            </th>

                            {columns.map((row, i) => (
                                <th key={i} className="text-center text-nowrap" style={{ width: "100px" }}>
                                    {row.textName}
                                    {row.isSort && (
                                        <>
                                            <a
                                                onClick={() => {
                                                    appStore.setParams({
                                                        orderby: `${row.colName} DESC`,
                                                        refresh: Date.now(),
                                                    });
                                                }}
                                                className={
                                                    appStore.params.orderby === `${row.colName} DESC` ? "" : "text-dark"
                                                }
                                            >
                                                <i className="bi bi-arrow-down"></i>
                                            </a>
                                            <a
                                                onClick={() => {
                                                    appStore.setParams({
                                                        orderby: `${row.colName} ASC`,
                                                        refresh: Date.now(),
                                                    });
                                                }}
                                                className={
                                                    appStore.params.orderby == `${row.colName} ASC` ? "" : "text-dark"
                                                }
                                            >
                                                <i className="bi bi-arrow-up"></i>
                                            </a>
                                        </>
                                    )}
                                </th>
                            ))}
                        </tr>
                        {children}
                    </tbody>
                </table>

                <div className="d-flex justify-content-between">
                    <button type="submit" className="btn btn-danger btn-sm">
                        삭제
                    </button>
                    {appStore.params.board_id !== "cscenter" && (
                        // <button
                        //     type="button"
                        //     className="btn btn-primary btn-sm"
                        //     onClick={() => {
                        //         appStore.setParams({ idx: 0 });
                        //     }}
                        // >
                        //     등록
                        // </button>
                        <button type="button" className="btn btn-primary btn-sm" onClick={onAdd}>
                            등록
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
});
