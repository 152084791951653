import { observer } from "mobx-react";

export default observer(({ children, onClose }) => {
    return (
        <div className="modal bg-dark bg-opacity-50" style={{ display: "block" }} id="backdrop">
            <div className="modal-dialog modal-xl modal-fullscreen-lg-down modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header py-3">
                        {/* 부모의 onClose 함수를 호출 */}
                        <button type="button" className="btn-close" onClick={onClose}></button>
                    </div>
                    {/* children을 이용해 태그 사이의 내용을 렌더링 */}
                    <div className="modal-body p-0">{children}</div>
                </div>
            </div>
        </div>
    );
});

