import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { crudStore } from "../../stores/CrudStore";
import { getAccessToken } from "../../utils/common";
import axios from "axios";
import ReplyBody from "./ReplyBody";
import MyModal from "./MyModal";

export default observer(() => {
    const [data, setData] = useState([]);

    useEffect(() => {
        (async () => {
            console.log("ASDASD");
            await fetch();
        })();
    }, [appStore.params.refresh2]);

    const fetch = async () => {
        const url = `${process.env.REACT_APP_HOST}/article/reply_detail/${appStore.params.idx2}/${appStore.params.id}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);

        setData(data.list);
    };

    const handleClose = () => {
        appStore.setParams({
            idx2: -1,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const frm = Object.fromEntries(new FormData(e.target).entries());
        e.target.memo.value = "";
        await crudStore.write(frm);
        appStore.setParams({
            refresh2: Date.now(),
        });
    };

    return (
        <MyModal onClose={handleClose}>
            {data.map((row, i) => (
                <div key={i} style={{ fontSize: "14px" }}>
                    <ReplyBody row={row} />
                </div>
            ))}

            <form onSubmit={handleSubmit}>
                <input type="hidden" name="table" defaultValue={appStore.params.table} />
                <input type="hidden" name="step" defaultValue="3" />
                <input type="hidden" name="parent_idx" defaultValue={appStore.params.idx2} />
                <input type="hidden" name="id" defaultValue={appStore.params.id} />
                <input type="hidden" name="name1" defaultValue={appStore.params.name1} />

                <div className="input-group input-group-sm mb-3 p-3">
                    <textarea
                        name="memo"
                        className="form-control"
                        rows="3"
                        placeholder="내용을 입력해주세요."
                        required
                    ></textarea>
                    <button className="btn btn-outline-secondary" type="submit">
                        댓글남기기
                    </button>
                </div>
            </form>
        </MyModal>
    );
});
