import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { memoStore } from "../../stores/MemoStore";
import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { getAccessToken, getRefreshToken, removeToken } from "../../utils/common";
import TokenVerify from "../components/TokenVerify";
import Loading from "../components/Loading";

const menuVariants = {
    hidden: {
        opacity: 1,
        x: "-100%", // 왼쪽으로 100% 이동 (화면 밖으로)
    },
    visible: {
        opacity: 1,
        x: 0,
    },
};

const menuChildVariants = {
    hidden: {
        opacity: 0,
        y: -20,
    },
    visible: {
        opacity: 1,
        y: 0,
    },
};

const Layout = observer(({ children }) => {
    const location = useLocation();
    const { pathname } = location; // pathname 가져오기
    const [windowWidth, setWindowWidth] = useState(window.innerWidth); // 윈도우 너비 상태 초기화

    useEffect(() => {
        // 윈도우 크기 변경을 감지하는 함수
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            // 윈도우 너비에 따라 메뉴 표시/숨김 설정
            if (window.innerWidth < 768) {
                appStore.setMenuShow(false);
            } else {
                appStore.setMenuShow(true);
            }
        };
        // 윈도우 크기 변경 이벤트 리스너 추가
        window.addEventListener("resize", handleResize);
        handleResize();
        // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [location.pathname.trim()]); // 빈 배열을 의존성 배열로 전달하여 마운트 시 한 번만 실행

    useEffect(() => {
        memoStore.loadMemo(pathname);
    }, [pathname]);

    const logout = () => {
        removeToken();
        window.location.href = "/login";
    };

    return (
        <div className="d-flex flex-column bg-light vh-100 fs-12">
            <div className="d-flex flex-row align-items-center">
                <button type="button" className="btn rounded-0 p-3" onClick={() => appStore.toggleLeftMenu()}>
                    <i className="bi bi-list"></i>
                </button>
                <div className="me-auto"></div>
                <Link to="/adm/myprofile" className="btn btn-link fs-12">
                    {localStorage.getItem("logined_name")} 님
                </Link>
                <button type="button" className="btn rounded-0" onClick={logout}>
                    <span className="fs-12">로그아웃</span>
                </button>
            </div>

            <div className="d-flex flex-row flex-fill">
                {appStore.isMenuShow && (
                    <AnimatePresence>
                        <motion.div
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={menuVariants}
                            className="d-flex flex-column h-100"
                        >
                            {appStore.menuList.map((row, i) => (
                                <div key={i} className="d-flex flex-column">
                                    <div
                                        className="d-flex flex-row btn btn-light rounded-0 py-3"
                                        style={{ width: "200px" }}
                                        onClick={() => {
                                            appStore.setMenuExpand(i);
                                        }}
                                    >
                                        <i className={`bi ${row.icon} fs-12 d-flex align-items-center`}></i>
                                        <div className="ms-2 fs-12 d-flex align-items-center text-nowrap">
                                            {row.title}
                                        </div>
                                        <i
                                            className={`bi bi-chevron-${
                                                row.isOpen ? "down" : "right"
                                            } mx-2 fs-12 d-flex align-items-center`}
                                        ></i>
                                    </div>
                                    {row.isOpen && (
                                        <motion.div
                                            initial="hidden"
                                            animate="visible"
                                            exit="hidden"
                                            variants={menuChildVariants}
                                            className="d-flex flex-column w-100"
                                        >
                                            {row.child.map((row2, j) => (
                                                <Link
                                                    key={j}
                                                    className={`btn text-primary text-start ps-5 py-3 fs-12 rounded-0 ${
                                                        row2.isSelected ? "bg-white" : "btn-light"
                                                    }`}
                                                    to={`${row2.link}`}
                                                    onClick={() => {
                                                        appStore.setSelected(i, j);
                                                    }}
                                                >
                                                    {row2.title}
                                                </Link>
                                            ))}
                                        </motion.div>
                                    )}
                                </div>
                            ))}
                        </motion.div>
                    </AnimatePresence>
                )}

                <div className="p-4 w-100 bg-white" style={{ borderRadius: "10px 10px 0 0" }}>
                    {children}

                    {location.pathname !== "/adm" && (
                        <div className="input-group mt-5">
                            <div className="form-floating">
                                <textarea
                                    className="form-control form-control-sm fs-12"
                                    style={{ height: "100px" }}
                                    value={memoStore.memo}
                                    onChange={(e) => memoStore.setMemo(e.target.value)}
                                ></textarea>
                                <label>메모</label>
                            </div>

                            <button
                                className="btn btn-outline-secondary fs-12"
                                type="button"
                                onClick={() => {
                                    memoStore.saveMemo(location.pathname);
                                }}
                            >
                                저장
                            </button>
                        </div>
                    )}
                </div>
            </div>

            <TokenVerify />
            {appStore.isLoading && <Loading />}
        </div>
    );
});

export default Layout;
