import { makeAutoObservable } from "mobx";
import axios from "axios";
import { getAccessToken } from "../utils/common";

class MyprofileStore {
    data = {};

    constructor() {
        makeAutoObservable(this);
    }

    async fetch() {
        const url = `${process.env.REACT_APP_HOST}/admin/my_profile`;
        console.log(url);
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        this.data = data;
    }

    async update(obj) {
        const id = obj.id;
        delete obj["id"];

        const url = `${process.env.REACT_APP_HOST}/admin/my_profile`;
        const { data } = await axios({
            url,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
            data: obj,
        });
        if (data.code === 0) {
            window.alert(data.msg);
            return;
        }

        this.data = obj;
        this.data.id = id;
        console.log(this.data);
    }
}

export const myprofileStore = new MyprofileStore();
