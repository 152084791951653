import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAccessToken } from "../../utils/common";
import { observer } from "mobx-react";
import { crudStore } from "../../stores/CrudStore";
import { appStore } from "../../stores/AppStore";
import Layout from "./Layout";


export default observer(() => {

    useEffect(()=>{

    },[])

    return (
        <Layout>
            Dev
        </Layout>
    );
});

