import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";
import { appStore } from "../../stores/AppStore";
import { crudStore } from "../../stores/CrudStore";
import MyModal from "../components/MyModal";
import Field from "../components/Field";
import ImageDrop from "../components/ImageDrop";
import Reply from "../components/Reply";

export default observer(({ data }) => {
    // const [data, setData] = useState({});

    // useEffect(() => {
    //     if (appStore.params.idx > 0) {
    //         fetch();
    //     }
    // }, [appStore.params.idx]);

    // const fetch = async () => {
    //     const data = await crudStore.readOne({
    //         table: appStore.params.table,
    //         idx: appStore.params.idx,
    //     });
    //     console.log(data);
    //     setData(data);
    // };

    const handleCloseModal = () => {
        appStore.setParams({
            idx: -1,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const frm = Object.fromEntries(new FormData(e.target).entries());
        if (frm.idx == "" || frm.idx == 0) {
            delete frm.idx;
        }
        console.log(frm);

        appStore.setLoading(true);
        await crudStore.write(frm);
        appStore.setLoading(false);

        if (frm.idx) {
            appStore.setParams({ refresh: Date.now() });
        } else {
            appStore.setParams({
                idx: -1,
                page: 1,
                refresh: Date.now(),
            });
        }
    };

    return (
        <MyModal onClose={handleCloseModal}>
            <form onSubmit={handleSubmit} className="p-3">
                <input name="idx" type="hidden" defaultValue={data ? data.idx : ""} />
                <input name="table" type="hidden" defaultValue={appStore.params.table || ""} />
                <input name="board_id" type="hidden" defaultValue={appStore.params.board_id} />
                <input name="id" type="hidden" defaultValue={data ? data.id : appStore.params.id} />
                <input name="name1" type="hidden" defaultValue={data ? data.name1 : appStore.params.name1} />
                <input name="step" type="hidden" defaultValue={"1"} />

                <Field
                    title="제목"
                    name1="title"
                    type="text"
                    readOnly={false}
                    required={true}
                    defaultValue={data ? data.title : ""}
                />
                <br />
                <Field
                    title="내용"
                    name1="memo"
                    type="textarea"
                    readOnly={false}
                    required={true}
                    defaultValue={data ? data.memo : ""}
                />
                <br />

                <div className="d-flex flex-wrap">
                    {Array(10)
                        .fill()
                        .map((_, i) => (
                            <div key={i} className="me-1 mt-2" style={{ width: "80px", height: "80px" }}>
                                <ImageDrop filename={data ? data[`filename${i}`] : ""} fieldname={`filename${i}`} />
                            </div>
                        ))}
                </div>

                <br />

                <div className="d-flex justify-content-between">
                    <div></div>
                    <button type="submit" className="btn btn-primary btn-sm">
                        {appStore.params.idx == 0 ? "등록" : "수정"}
                    </button>
                </div>
            </form>

            {appStore.params.idx > 0 && (
                <>
                    <hr />
                    <Reply />
                </>
            )}
        </MyModal>
    );
});
