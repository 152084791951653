import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAccessToken } from "../../utils/common";
import { observer } from "mobx-react";
import { crudStore } from "../../stores/CrudStore";
import { appStore } from "../../stores/AppStore";
import Layout from "./Layout";
import Table from "../components/Table";
import Pager from "../components/Pager";
import SearchForm from "../components/SearchForm";
import ArticleDetail from "./ArticleDetail";

const table = "BOARD_tbl";
const columns = [
    { textName: "번호", colName: "idx", isSort: true },
    { textName: "썸네일", colName: "filename0", isSort: false },
    { textName: "제목", colName: "title", isSort: true },
    { textName: "댓글수", colName: "reply_cnt", isSort: false },
    { textName: "조횟수", colName: "see", isSort: false },
    { textName: "좋아요수", colName: "like_cnt", isSort: false },
    { textName: "작성자", colName: "name1", isSort: false },
    { textName: "등록일", colName: "created", isSort: false },
    { textName: "수정일", colName: "modified", isSort: false },
];

const Article = observer(() => {
    const { board_id } = useParams();
    const [data, setData] = useState([]);
    const [row, setRow] = useState({});
    const [pageHelper, setPageHelper] = useState({});

    useEffect(() => {
        appStore.setParams({
            board_id,
            id: localStorage.getItem("logined_id"),
            name1: localStorage.getItem("logined_name"),
            page: 1,
            table: "BOARD_tbl",
        });

        fetch();
    }, [board_id]);

    useEffect(() => {
        fetch();
    }, [appStore.params.refresh]);

    const fetch = async () => {
        appStore.setLoading(true);
        const url = `${process.env.REACT_APP_HOST}/article/${appStore.params.board_id}?id=${appStore.params.id}&search_column=${appStore.params.search_column}&search_value=${appStore.params.search_value}&orderby=${appStore.params.orderby}&page=${appStore.params.page}`;
        const { data } = await axios({
            url,
            method: "GET",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: `Bearer ${getAccessToken()}`,
            },
        });
        console.log(data);

        setData(data.list);
        setPageHelper(data.page_helper);

        appStore.setLoading(false);
    };

    const handleAdd = () => {
        setRow(null);
        appStore.setParams({ idx: 0 });
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        // 선택된 항목을 배열로 수집
        const arr =
            e.target.idx.length === undefined
                ? [e.target.idx.value] // 단일 항목 처리
                : [...e.target.idx].filter((obj) => obj.checked).map((obj) => obj.value); // 여러 항목 처리

        // 선택된 항목이 없을 경우
        if (arr.length === 0) {
            return window.alert("체크된 항목이 없습니다.");
        }

        // 삭제 확인
        if (!window.confirm("체크된 항목을 삭제 하시겠습니까?")) return;
        await crudStore.updateIsUse(arr, table);
        appStore.setParams({ refresh: Date.now() });
    };

    return (
        <Layout>
            <SearchForm
                columns={[
                    { value: "title", text: "제목" },
                    { value: "memo", text: "내용" },
                ]}
            />
            <Table columns={columns} table={table} onAdd={handleAdd} onDelete={handleDelete}>
                {data.map((row, i) => (
                    <tr key={row.idx}>
                        <td className="text-center align-middle">
                            <input type="checkbox" name="idx" value={row.idx} />
                        </td>
                        <td className="text-center text-nowrap align-middle">{row.idx}</td>

                        <td className="text-center align-middle">
                            {row.filename0 && (
                                <img src={row.filename0} width={30} height={30} style={{ objectFit: "cover" }} />
                            )}
                        </td>
                        <td className="text-nowrap align-middle">
                            <button
                                type="button"
                                className="btn btn-sm btn-link fs-12"
                                onClick={() => {
                                    appStore.setParams({
                                        idx: row.idx,
                                    });
                                    setRow(row);
                                }}
                            >
                                {row.title == "" ? "제목없음" : row.title}
                            </button>
                        </td>

                        <td className="text-center text-nowrap align-middle">{row.reply_cnt}</td>
                        <td className="text-center text-nowrap align-middle">{row.see_cnt}</td>
                        <td className="text-center text-nowrap align-middle">{row.like_cnt}</td>
                        <td className="text-center text-nowrap align-middle">{row.name1}</td>
                        <td className="text-center text-nowrap align-middle">{row.created}</td>
                        <td className="text-center text-nowrap align-middle">{row.modified}</td>
                    </tr>
                ))}
            </Table>

            <Pager pageHelper={pageHelper} />

            {appStore.params.idx > -1 && <ArticleDetail data={row} />}
        </Layout>
    );
});
export default Article;
